import { saveAs } from 'file-saver';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';

function App() {
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState('booth_data.json');

  const isRowEmpty = useCallback((row) => {
    return Object.values(row).every(
      (value) => value === '' || value === null || (typeof value === 'object' && isRowEmpty(value)) || value === false
    );
  }, []);

  useEffect(() => {
    if (data.length === 0 || !isRowEmpty(data[data.length - 1])) {
      setData((prevData) => [...prevData, createEmptyRow()]);
    }
  }, [data, isRowEmpty]);

  // useEffect(() => {
  //   if (data.length > 0 && !isRowEmpty(data[data.length - 1])) {
  //     setData((prevData) => [...prevData, createEmptyRow()]);
  //   }
  // }, [data, isRowEmpty]);

  const createEmptyRow = () => ({
    booth_number: '',
    booth_name: '',
    operator_name: '',
    category: '',
    items_sold: '',
    all_tag: '',
    info_url: '',
    twt_id: '',
    coordinates: { x: '', y: '' },
    head: false,
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const json = JSON.parse(e.target.result);
      setData([...json, createEmptyRow()]);
    };
    reader.readAsText(file);
  };

  const handleChange = (index, field, value) => {
    const updatedData = [...data];
    if (field.startsWith('coordinates')) {
      const coordField = field.split('.')[1];
      updatedData[index].coordinates[coordField] = value;
    } else if (field === 'head') {
      updatedData[index][field] = value.target.checked;
    } else {
      updatedData[index][field] = value;
    }
    setData(updatedData);
  };

  const handleDelete = (index) => {
    const updatedData = [...data];
    updatedData.splice(index, 1);
    setData(updatedData);
  };

  const saveToFile = () => {
    const filteredData = data.filter((row) => !isRowEmpty(row)); // Exclude empty rows
    const blob = new Blob([JSON.stringify(filteredData, null, 2)], { type: 'application/json' });
    saveAs(blob, fileName);
  };

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h1>Booth Editor</h1>
        </Col>
      </Row>
      <Row className="my-4">
        <Col>
          <Form.Group controlId="formFile">
            <Form.Label>Upload JSON File</Form.Label>
            <Form.Control type="file" onChange={handleFileUpload} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          {data.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Booth Number</th>
                  <th>Booth Name</th>
                  <th>Operator Name</th>
                  <th>Category</th>
                  <th>Items Sold</th>
                  <th>All Tag</th>
                  <th>Info URL</th>
                  <th>TWT ID</th>
                  <th>Coordinates</th>
                  <th>Head</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.booth_number}
                        onChange={(e) => handleChange(index, 'booth_number', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.booth_name}
                        onChange={(e) => handleChange(index, 'booth_name', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.operator_name}
                        onChange={(e) => handleChange(index, 'operator_name', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.category}
                        onChange={(e) => handleChange(index, 'category', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.items_sold}
                        onChange={(e) => handleChange(index, 'items_sold', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.all_tag}
                        onChange={(e) => handleChange(index, 'all_tag', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.info_url}
                        onChange={(e) => handleChange(index, 'info_url', e.target.value)}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        value={item.twt_id}
                        onChange={(e) => handleChange(index, 'twt_id', e.target.value)}
                      />
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span>X: </span>
                        <Form.Control
                          type="number"
                          value={item.coordinates.x}
                          onChange={(e) => handleChange(index, 'coordinates.x', e.target.value)}
                          style={{ width: '80px', margin: '0 5px' }}
                        />
                        <span>Y: </span>
                        <Form.Control
                          type="number"
                          value={item.coordinates.y}
                          onChange={(e) => handleChange(index, 'coordinates.y', e.target.value)}
                          style={{ width: '80px', margin: '0 5px' }}
                        />
                      </div>
                    </td>
                    <td>
                      <Form.Check
                        type="checkbox"
                        checked={item.head}
                        onChange={(e) => handleChange(index, 'head', e)}
                      />
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => handleDelete(index)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      {data.length > 0 && (
        <Row className="my-4">
          <Col>
            <Form.Group controlId="fileName">
              <Form.Label>File Name</Form.Label>
              <Form.Control
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
            </Form.Group>
            <Button variant="primary" onClick={saveToFile}>Save JSON</Button>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default App;
